// File to define common Gatsby browser & ssr function(s)
import React from 'react';
import { LocaleProvider } from '@vp/digital-locale-lib';
import { AuthProvider } from '@vp/digital-auth-lib';
import {
  RuntimeContextProvider,
  getClientEnvironment,
} from '@vp/digital-environment-lib';
import { UrlContextProvider } from '@vp/digital-url-context-lib';
import { RootLayout, HtmlAttributes } from '@vp/digital-site-layout-lib';

import { StyleKeys, ScriptKeys } from './src/swanKeys';
import { BookendsWrapper } from '@vp/digital-bookends-lib';
import { Helmet } from 'react-helmet';
import Layout from './src/components/common/Layout';
import {
  PricingContentProvider,
  PricingContextProvider,
} from '@vp/digital-pricing-lib';

const ProductPageWrapper = ({ pageContext, location, children }) => {
  const {
    locale,
    directAccessClientId,
    trackingConfiguration,
    domainsBreadcrumbs,
    isSlimHeader,
    hideFooter,
    hideHeader,
    billingPeriods,
    requireAuth,
  } = pageContext;
  return (
    <LocaleProvider rawLocaleIdentifier={locale}>
      <RuntimeContextProvider getEnvironment={getClientEnvironment}>
        <AuthProvider directAccessClientId={directAccessClientId}>
          <PricingContextProvider>
            <PricingContentProvider pricingContent={billingPeriods}>
              <UrlContextProvider location={location}>
                <RootLayout
                  swanStyleKeys={StyleKeys}
                  swanScriptKeys={ScriptKeys}
                >
                  <HtmlAttributes />
                  <BookendsWrapper
                    trackingConfiguration={trackingConfiguration}
                    locale={locale}
                    renderMetaWith={Helmet}
                    isSlimHeader={isSlimHeader}
                    hideFooter={hideFooter}
                    hideHeader={hideHeader}
                    tenant={isSlimHeader ? 'wix' : 'vistaprint'}
                  >
                    <Layout
                      domainsBreadcrumbs={domainsBreadcrumbs}
                      requireAuth={requireAuth}
                    >
                      {children}
                    </Layout>
                  </BookendsWrapper>
                </RootLayout>
              </UrlContextProvider>
            </PricingContentProvider>
          </PricingContextProvider>
        </AuthProvider>
      </RuntimeContextProvider>
    </LocaleProvider>
  );
};

export const wrapPageElement = ({ element, props }) => {
  const {
    pageContext: { wrap },
  } = props;
  return wrap ? (
    <ProductPageWrapper {...props}>{element}</ProductPageWrapper>
  ) : (
    element
  );
};
