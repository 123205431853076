exports.components = {
  "component---gatsby-plugin-cart-redirect-src-cart-redirect-page-tsx": () => import("./../../../../gatsby-plugin-cart-redirect/src/CartRedirectPage.tsx" /* webpackChunkName: "component---gatsby-plugin-cart-redirect-src-cart-redirect-page-tsx" */),
  "component---gatsby-plugin-index-src-404-404-tsx": () => import("./../../../../gatsby-plugin-index/src/404/404.tsx" /* webpackChunkName: "component---gatsby-plugin-index-src-404-404-tsx" */),
  "component---gatsby-plugin-index-src-index-index-tsx": () => import("./../../../../gatsby-plugin-index/src/index/Index.tsx" /* webpackChunkName: "component---gatsby-plugin-index-src-index-index-tsx" */),
  "component---gatsby-plugin-index-src-page-index-page-index-tsx": () => import("./../../../../gatsby-plugin-index/src/page-index/PageIndex.tsx" /* webpackChunkName: "component---gatsby-plugin-index-src-page-index-page-index-tsx" */),
  "component---src-components-wix-domains-cancel-page-wix-domains-cancel-page-tsx": () => import("./../../../src/components/wixDomainsCancelPage/WixDomainsCancelPage.tsx" /* webpackChunkName: "component---src-components-wix-domains-cancel-page-wix-domains-cancel-page-tsx" */),
  "component---src-components-wix-domains-error-page-wix-domains-error-page-tsx": () => import("./../../../src/components/wixDomainsErrorPage/WixDomainsErrorPage.tsx" /* webpackChunkName: "component---src-components-wix-domains-error-page-wix-domains-error-page-tsx" */),
  "component---src-components-wix-domains-pdp-wix-domains-pdp-tsx": () => import("./../../../src/components/wixDomainsPdp/WixDomainsPdp.tsx" /* webpackChunkName: "component---src-components-wix-domains-pdp-wix-domains-pdp-tsx" */),
  "component---src-components-wix-domains-registration-page-wix-domains-registration-page-tsx": () => import("./../../../src/components/wixDomainsRegistrationPage/WixDomainsRegistrationPage.tsx" /* webpackChunkName: "component---src-components-wix-domains-registration-page-wix-domains-registration-page-tsx" */),
  "component---src-components-wix-domains-search-and-suggestions-page-wix-domains-search-and-suggestions-page-tsx": () => import("./../../../src/components/WixDomainsSearchAndSuggestionsPage/WixDomainsSearchAndSuggestionsPage.tsx" /* webpackChunkName: "component---src-components-wix-domains-search-and-suggestions-page-wix-domains-search-and-suggestions-page-tsx" */),
  "component---src-components-wix-domains-site-selector-page-wix-domains-site-selector-page-tsx": () => import("./../../../src/components/wixDomainsSiteSelectorPage/WixDomainsSiteSelectorPage.tsx" /* webpackChunkName: "component---src-components-wix-domains-site-selector-page-wix-domains-site-selector-page-tsx" */),
  "component---src-components-wix-domains-terms-and-conditions-page-wix-domains-terms-and-conditions-page-tsx": () => import("./../../../src/components/wixDomainsTermsAndConditionsPage/WixDomainsTermsAndConditionsPage.tsx" /* webpackChunkName: "component---src-components-wix-domains-terms-and-conditions-page-wix-domains-terms-and-conditions-page-tsx" */),
  "component---src-components-wix-websites-upsell-page-wix-websites-upsell-page-tsx": () => import("./../../../src/components/wixWebsitesUpsellPage/WixWebsitesUpsellPage.tsx" /* webpackChunkName: "component---src-components-wix-websites-upsell-page-wix-websites-upsell-page-tsx" */)
}

