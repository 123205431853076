import React, { FC, useState, useRef, useEffect } from 'react';
import {
  useWebAuth,
  useIdentity,
  IS_CUSTOMER,
  CIMPRESS_INTERNAL,
} from '@vp/digital-auth-lib';
import { getLogger } from '@vp/digital-logging-lib';
import { DOMAINS_MPV_ID } from '@vp/digital-product-availability-lib';
import { MainLayout } from '@vp/digital-site-layout-lib';
import { BoundedContent, ScreenClassProvider } from '@vp/swan';
import { Breadcrumbs } from '@vp/breadcrumbs-react-component';

import Loading from './Loading';
import { isProduction } from '@vp/digital-environment-lib';

type layoutProps = {
  domainsBreadcrumbs: boolean;
  requireAuth?: boolean;
  isInternalUserView?: boolean;
  children?: React.ReactNode;
};
const Layout: FC<layoutProps> = ({
  domainsBreadcrumbs,
  requireAuth = false,
  isInternalUserView,
  children,
}) => {
  const webAuth = useWebAuth();
  const { isSignedIn, profile } = useIdentity();
  const [isAuthorized, setAuthorized] = useState(!requireAuth);
  const { current: logger } = useRef(getLogger({ page: DOMAINS_MPV_ID }));

  let ignoreSignIn = false;

  if (typeof window !== 'undefined' && window.location?.search) {
    const urlParams: URLSearchParams = new URLSearchParams(
      window.location.search
    );

    ignoreSignIn = urlParams?.get('ignoreSignIn')?.toLowerCase() === 'true';
  }

  useEffect(() => {
    if (!requireAuth) {
      setAuthorized(true);
      return;
    }
    if (!webAuth) return;

    if (!isSignedIn && (isProduction() || !ignoreSignIn)) webAuth?.signIn();
    else if (
      profile &&
      ((profile[IS_CUSTOMER] && isInternalUserView) ||
        (profile[CIMPRESS_INTERNAL] && !isInternalUserView))
    ) {
      logger.warn(
        `User Is Signed in using a different Client. Trying to sign in again`
      );
      webAuth.signOut();
      webAuth.signIn();
    } else setAuthorized(true);
  }, [
    ignoreSignIn,
    isInternalUserView,
    isSignedIn,
    logger,
    profile,
    requireAuth,
    webAuth,
  ]);
  if (!isAuthorized) return <Loading />;

  return (
    <>
      <MainLayout>
        <BoundedContent>
          <Breadcrumbs data={domainsBreadcrumbs} />
        </BoundedContent>
        <ScreenClassProvider>{children}</ScreenClassProvider>
      </MainLayout>
    </>
  );
};
export default Layout;
